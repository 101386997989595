<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="状态" name="processStatus" class="ui-form__item">
						<a-select  v-model:value="formState.processStatus" placeholder="请选择状态"
							allow-clear style="width: 195px;">
							<a-select-option :value="1">待审核</a-select-option>
							<a-select-option :value="2">审核通过</a-select-option>
							<a-select-option :value="3">审核拒绝</a-select-option>
							<a-select-option :value="4">用户已撤销</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="申请时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['report_bill_list_export']" style="margin-right: 20px;">
							<exportReport type="orderInvoiceExportStrategy" :searchData="searchData"></exportReport>
						</span>
						<a-button v-permission="['report_bill_list_process']" type="primary" style="margin-right: 20px;" :disabled="selectedRowKeys.length == 0" @click="onBatchProcess(0)">批量通过</a-button>
						<a-button v-permission="['report_bill_list_process']" type="primary" :disabled="selectedRowKeys.length == 0" @click="onBatchProcess(1)">批量拒绝</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 2500 }" :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, getCheckboxProps: getCheckboxProps }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'userInfo'">
							<div>用户昵称：{{ record.userInfo.nickName || '-' }}</div>
							<div>手机号：{{ record.userInfo.phone || '-' }}</div>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'type'">
							{{ record.type == 1 ? '电子普通发票' : '专用发票' }}
						</template>
						<template v-if="column.key === 'titleType'">
							<a-tag color="#2db7f5">{{ record.titleType === 1 ? '个人' : '单位' }}</a-tag>
							<div v-if="record.titleType === 2">
								<div>单位税号：{{ record.taxRegistrationNumber || '-' }}</div>
								<div>注册地址：{{ record.registerAddress || '-' }}</div>
								<div>注册手机号：{{ record.registerPhone || '-' }}</div>
								<div>开户银行：{{ record.registerBank || '-' }}</div>
								<div>开户行账户：{{ record.registerBankNumber || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key == 'orderInfo'">
							<div>
								<div v-for="(item, itemIndex) in record.goodsNameList" :key="item">
									{{ item }}
									<text v-if="itemIndex != record.goodsNameList.length - 1">、</text>
								</div>
							</div>
						</template>
						<template v-if="column.key === 'orderSnList'">
							<div>
								<div v-for="(item, itemIndex) in record.orderSnList" :key="item">
									{{ item }}
									<text v-if="itemIndex != record.orderSnList.length - 1">、</text>
								</div>
							</div>
						</template>
						<template v-if="column.key == 'status'">
							<div>{{ ['待审核', '审核通过', '审核拒绝', '用户已撤销'][record.processStatus - 1] }}</div>
						</template>
						<template v-if="column.key == 'action'">
							<a-dropdown :trigger="['click', 'hover']" v-if="record.processStatus === 1">
								<a-button>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['report_bill_list_process']" @click="onProcess(record, 1)">
											<a-menu-item>
												审核通过
											</a-menu-item>
										</div>
										<div v-permission="['report_bill_list_process']" @click="onProcess(record, 2)">
											<a-menu-item>
												审核拒绝
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
							<div v-else>-</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import exportReport from '@/components/exportReport/exportReport.vue';
	import { getOrderInvoiceList, invoiceProcess, invoiceBatchProcess } from '@/service/modules/report.js';
	export default {
		components: {
			Icon,
			exportReport
		},
		data() {
			return {
				loading: false,
				selectedRowKeys:[],
				searchData: {},
				time: [],
				formState: {},
				columns: [{
					title: '申请用户信息',
					key: 'userInfo',
					width: 220
				}, {
					title: '申请时间',
					key: 'createTime',
					width: 180
				}, {
					title: '姓名',
					dataIndex: 'fullName',
					width: 120
				}, {
					title: '手机号',
					dataIndex: 'phone',
					width: 120
				}, {
					title: '邮箱',
					dataIndex: 'email',
					width: 190
				}, {
					title: "发票类型",
					key: "type",
					width: 120
				}, {
					title: '发票内容',
					dataIndex: 'content',
					width: 120
				}, {
					title: '抬头类型',
					key: 'titleType',
				}, {
					title: '抬头名称',
					dataIndex: 'titleName',
				}, {
					title: '订单内容',
					key: 'orderInfo'
				}, {
					title: '订单号',
					key: 'orderSnList'
				}, {
					title: '状态',
					key: 'status',
					width: 120
				}, {
					title: '审核员',
					dataIndex: 'processUserName',
					width: 150
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 100
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.onSearch();
		},
		methods: {
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startTime = this.time[0].startOf('days').unix();
					this.searchData.endTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			// 获取影票座位列表
			async getData() {
				this.loading = true;
				let ret = await getOrderInvoiceList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCountl
				}
			},
			onBatchProcess(status) {
				this.$confirm({
					title: '提示',
					content: `确定批量${ status === 1 ? '同意' :'拒绝' }申请吗？`,
					onOk: async ()=> {
						this.loading = true;
						let ret = await invoiceBatchProcess({
							id: this.selectedRowKeys,
							status: status
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('审核完成');
							this.selectedRowKeys = [];
							this.getData();
						}
					}
				})
			},
			onProcess(item, status) {
				this.$confirm({
					title: '提示',
					content: `确定${ status === 1 ? '同意' :'拒绝' }该申请吗？`,
					onOk: async ()=> {
						this.loading = true;
						let ret = await invoiceProcess({
							id: item.id,
							status: status
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('审核完成');
							let index = this.selectedRowKeys.indexOf(item.id);
							if (index !== -1) {
								this.selectedRowKeys.splice(index, 1);
							}
							this.getData();
						}
					}
				})
			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1 && item.processStatus === 0) {
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			getCheckboxProps(record) {
				return {
					disabled: record.processStatus !== 0,
					id: record.id.toString()
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>